import { find, uniq, get, isPlainObject } from 'lodash-es'
import * as Sentry from '@sentry/browser'

export const useUser = defineStore('user', () => {
  const { $api } = useNuxtApp()

  const { getCookieParams } = useHelpers()

  const ui = useUi()

  const started = ref(false)

  const data = ref({})

  const token = ref(null)

  const isEditing = ref(false)

  const isNew = ref(false)

  const queue = useCookie('queue', {
    default: () => {
      return []
    }
  })

  const queuable = { castVote, saveMetadata }

  const voted = ref(false)

  const submittingVotes = ref([])

  const tokenCookie = useCookie('jwt', getCookieParams())

  const trackingCookie = useCookie('tracking')

  const isGuest = computed(() => !Object.keys(data.value).length)

  const name = computed(() => isGuest.value ? null : [data.value.first_name, data.value.last_name].join(' ').trim())

  const completedExercises = computed(() => data.value.settings?.exercises || [])

  const hasTeamOrders = computed(() => {
    if (!data.value.orders) return false
    return data.value.orders.some(order => order.quantity > 1)
  })

  const canUpgrade = computed(() => {
    return data.value.orders?.some(order => order.can_upgrade)
  })

  const upgradeableOrders = computed(() => {
    return data.value.orders?.filter(order => order.can_upgrade)
  })

  const vouchersPurchased = computed(() => get(data.value, 'team_vouchers', []))

  const vouchersRedeemed = computed(() => get(data.value, 'vouchers', []))

  const hasJoinedDiscord = computed(() => {
    const discord = data.value.socials.find(social => social.name === 'discord')

    return discord?.joined || false
  })

  function start (payload) {
    // Write JWT cookie
    tokenCookie.value = payload.token

    // Clear tracking
    trackingCookie.value = null

    // Update store
    started.value = true
    data.value = payload.data
    token.value = payload.token
    console.info('Session started')

    if (process.client) {
      // Reset user related stores
      useCertificates().reset()
      useExams().reset()
      useOrders().reset()
      useProducts().reset()
      useSummaries().reset()
      useVouchers().reset()

      // Process queue
      processQueue()

      Sentry.setUser({
        id: data.value.id,
        email: data.value.email
      })
    }
  }

  function clear () {
    data.value = {}
    token.value = null
    tokenCookie.value = null
    Sentry.setUser(null)
  }

  const toggleEdit = () => {
    isEditing.value = !isEditing.value
  }

  function markAsNew () {
    isNew.value = true
  }

  async function login ({ email, password }) {
    const payload = await $api.post('/login', { email, password, ...trackingCookie.value })
    start(payload)
  }

  async function loginSocial ({ code, provider }) {
    const payload = await $api.post(`/login/${provider}/callback`, { code })
    start(payload)

    const isNewUser = data.value.created_minutes_ago < 3
    if (isNewUser) markAsNew()
  }

  async function joinDiscord () {
    const { link } = await $api.post('/join/discord')

    return link
  }

  async function register ({ first_name, last_name, email, password }) {
    const payload = await $api.post('/register', {
      first_name,
      last_name,
      email,
      password,
      password_confirmation: password,
      ...trackingCookie.value
    })

    // ui.displayFlashMessage()
    markAsNew()

    start(payload)
  }

  async function verifyEmail ({ id, hash, expires, signature }) {
    await $api.get(`/verify-email/${id}/${hash}`, {
      expires, signature
    })
  }

  async function impersonate (code) {
    const payload = await $api.post(`/impersonate/${code}`)

    clear()
    start(payload)
  }

  async function validate () {
    // Skip if the browser does not have a "jwt" cookie or the session has already started
    if (!tokenCookie.value || started.value) return

    try {
      const payload = await $api.get('/auth')
      start(payload)
    } catch (e) {
      clear()
    }
  }

  async function update ({ first_name, last_name, email, occupation, twitter, linkedin }) {
    const payload = await $api.post('/auth', { first_name, last_name, email, occupation, twitter, linkedin })

    data.value = payload.data
  }

  async function changePassword ({ current_password, new_password }) {
    await $api.put('/change-password', { current_password, new_password })
    clear()
  }

  function getPasswordRecoveryLink (email) {
    return $api.post('/forgot-password', { email })
  }

  function resetPassword (params) {
    return $api.post('/reset-password', params)
  }

  async function logout () {
    try {
      await $api.post('/logout')
      clear()
    } catch (e) {
      console.error(e)
    }
  }

  async function storeSetting (payload) {
    data.value.settings = await $api.post('/user/settings', payload)
  }

  async function completeExercise (id) {
    let completed = data.value.settings?.exercises || []
    completed.push(id)
    completed = uniq(completed)
    return await storeSetting({ exercises: completed })
  }

  async function saveMetadata (payload) {
    try {
      await $api.post('/user/metadata', payload)
      data.value.metadata = isPlainObject(data.value.metadata) ? data.value.metadata : {}
      data.value.metadata.interest = data.value.metadata.interest || []
      data.value.metadata.interest.push(...payload.interest)
    } catch (e) {
      console.error('Error saving metadata', e)
    }
  }

  async function castVote ({ slug, votes }) {
    try {
      const votesIds = votes.map((vote) => vote.value)

      submittingVotes.value.push(...votesIds)

      await $api.post(`/polls/${slug}/vote`, { votes })

      voted.value = true
      submittingVotes.value = []

      data.value.metadata = isPlainObject(data.value.metadata) ? data.value.metadata : {}
      data.value.metadata.interest = data.value.metadata.interest || []
      data.value.metadata.interest.push(...votesIds)
    } catch (e) {
      console.error('Error casting vote', e)
    }
  }

  function addToQueue (params) {
    queue.value?.push(params)

    if (isGuest.value) return
    processQueue()
  }

  function processQueue () {
    console.info('Process queue...')

    queue.value?.forEach(async (item) => {
      try {
        await queuable[item.method](item.payload)
        if (item.flashMessageKey) {
          ui.flashMessageKey = item.flashMessageKey
          ui.displayFlashMessage()
        }
      } catch (e) {
        console.error(e)
        ui.showErrorModal(e)
      }
    })

    queue.value = []
  }

  function findVoucher (certificationId, productType, pool) {
    return find(pool, voucher => voucher?.product?.certification_id === certificationId && voucher?.product?.type === productType)
  }

  function accessTo (certificationId, productType) {
    if (findVoucher(certificationId, productType, vouchersRedeemed.value)) return 'REDEEMED'
    if (findVoucher(certificationId, productType, vouchersPurchased.value)) return 'PURCHASED'
    return null
  }

  return {
    started,
    start,
    data,
    token,
    isGuest,
    name,
    queue,
    addToQueue,
    voted,
    submittingVotes,
    login,
    loginSocial,
    joinDiscord,
    hasJoinedDiscord,
    register,
    impersonate,
    validate,
    update,
    changePassword,
    getPasswordRecoveryLink,
    resetPassword,
    logout,
    storeSetting,
    saveMetadata,
    completeExercise,
    completedExercises,
    castVote,
    accessTo,
    verifyEmail,
    vouchersPurchased,
    vouchersRedeemed,
    hasTeamOrders,
    canUpgrade,
    upgradeableOrders,
    findVoucher,
    processQueue,
    isEditing,
    toggleEdit,
    isNew,
    markAsNew
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUser, import.meta.hot))
}
