<script setup>
const ui = useUi()
const user = useUser()

const route = useRoute()
const { getFlashMessageKey } = useHelpers()

const logoLink = route.path === '/' ? '#top' : '/#top'

const sitemap = ref([
  {
    route: 'Blog',
    url: '/blog',
    target: ''
  },
  {
    route: 'Available Certificates',
    url: '/#available-certificates'
  },
  {
    route: 'Cast your Vote',
    url: '/#cast-your-vote'
  },
  {
    route: 'Hire Developers',
    url: '/#hire-developers'
  }
])

const isNavOpen = ref(false)

function openAuthModal () {
  ui.flashMessageKey = getFlashMessageKey()
  ui.openAuthModal()
}

function toggle () {
  isNavOpen.value = !isNavOpen.value
}

async function logout () {
  await user.logout()
  window.location.href = '/'
}
</script>

<template>
  <div class="sticky top-0 z-[60] w-full bg-white/60 backdrop-blur-2xl dark:bg-eerie-black/60">
    <BannerTop />
    <BannerTopJavascript />
    <BannerTopNuxt />
    <BannerTopAngular />
    <AppContainer class="flex h-[74px] items-center">
      <Teleport to="body">
        <div
          v-if="isNavOpen"
          class=" fixed left-0 top-15 z-[1000] h-fit w-72 overflow-y-scroll bg-white shadow-xl xl:hidden dark:bg-dark-gunmetal">
          <nav class="relative w-full p-6">
            <ul class="flex flex-col gap-6">
              <li
                v-for="item in sitemap"
                :key="item.link"
                class="text-eerie-black hover:text-azure-radiance dark:text-body-gray dark:hover:text-azure-radiance">
                <NuxtLink
                  :to="item.url"
                  :target="item.target"
                  class="flex items-center whitespace-nowrap"
                  @click="isNavOpen=false">
                  <span>
                    {{ item.route }}
                  </span>
                  <InlineSvg
                    v-if="item.icon"
                    hard-code
                    :src="item.icon"
                    class="h-4 fill-current" />
                </NuxtLink>
              </li>
              <AppThemeSwitch class="w-fit" />
            </ul>
          </nav>
        </div>
      </Teleport>
      <div class="flex w-full items-center justify-between gap-8">
        <div class="flex items-center gap-4 md:gap-8">
          <AppToggleNav
            class="xl:hidden"
            v-bind="{ isNavOpen }"
            @click="toggle" />
          <NuxtLink :to="logoLink">
            <InlineSvg
              hard-code
              src="/images/homepage/logo-certificates-footer.svg"
              class="hidden h-6 w-auto md:h-10 dark:block" />
            <InlineSvg
              hard-code
              src="/images/homepage/logo-certificates-light.svg"
              class="h-6 w-auto md:h-10 dark:hidden" />
          </NuxtLink>
        </div>
        <div class=" flex w-fit items-center gap-6 xl:gap-8">
          <ul class="hidden items-center gap-6 xl:inline-flex xl:gap-8">
            <li
              v-for="item in sitemap"
              :key="item.link"
              class="text-eerie-black hover:text-azure-radiance dark:text-body-gray dark:hover:text-azure-radiance">
              <NuxtLink
                :to="item.url"
                :target="item.target"
                class="flex items-center whitespace-nowrap">
                <span>
                  {{ item.route }}
                </span>
                <InlineSvg
                  v-if="item.icon"
                  hard-code
                  :src="item.icon"
                  class="h-4 fill-current" />
              </NuxtLink>
            </li>
          </ul>
          <button
            v-if="user.isGuest"
            class="btn-primary px-5 py-3"
            @click="openAuthModal">
            Sign up
          </button>
          <button
            v-else
            class="px-2 py-3 text-eerie-black hover:text-azure-radiance dark:text-body-gray dark:hover:text-azure-radiance"
            @click="logout">
            Log Out
          </button>
          <AppThemeSwitch class="hidden xl:flex" />
        </div>
      </div>
    </AppContainer>
  </div>
  <AuthModal />
</template>
