<script setup>
const props = defineProps({
  mode: {
    type: String,
    default: 'login'
  },
  flashMessageKey: {
    type: String,
    default: null
  },
  queue: {
    type: Object,
    default: null
  }
})

const ui = useUi()
const user = useUser()
const { socials } = useSocialAuthentication()
const emit = defineEmits(['beforeRedirect'])
const disabled = ref(false)

function auth (social) {
  disabled.value = true

  if (props.queue) user.addToQueue(props.queue)
  if (props.flashMessageKey) ui.flashMessageKey = props.flashMessageKey
  ui.redirectUrl = ui.redirectUrl || window.location.pathname
  emit('beforeRedirect')
  window.location.href = social.route
}
</script>

<template>
  <div
    class="flex flex-col justify-center gap-4">
    <button
      v-for="social in socials"
      :key="social.name"
      :disabled="disabled"
      class="btn-primary-outline inline-flex w-full gap-4 px-6 py-4 disabled:opacity-80"
      @click="auth(social)">
      <div class="min-w-8">
        <InlineSvg
          hard-code
          :src="`/images/social-${social.name.toLowerCase()}.svg`"
          class="mr-2 size-6 shrink-0" />
      </div>
      <span>{{ mode === 'login' ? 'Log In' : 'Sign Up' }} with {{ social.name }}</span>
    </button>
  </div>
</template>
