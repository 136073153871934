export const useTrialExam = defineStore('trialExam', () => {
  const { $api } = useNuxtApp()

  const fetched = ref(false)
  const trials = ref([])

  async function fetchTrials () {
    const { data } = await $api.get('/trial-exams')

    if (data) {
      fetched.value = true
      trials.value = data
    }
  }

  async function getExam (id) {
    const { data: exam } = await $api.get(`/trial-exams/${id}`)

    return exam
  }

  function getTrainingSlug (certificationId) {
    return trials.value.find(t => t.id === certificationId)?.training_slug
  }

  function isTrial (certificationId) {
    return trials.value.findIndex((trial) => trial.id === certificationId) > -1
  }

  function isTrialAvailable (certificationId) {
    return trials.value.findIndex((trial) => trial.id === certificationId && trial.is_available) > -1
  }

  return {
    trials,
    fetched,
    fetchTrials,
    getExam,
    getTrainingSlug,
    isTrial,
    isTrialAvailable
  }
})
