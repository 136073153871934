export const useCertificates = defineStore('certificates', () => {
  const { $api } = useNuxtApp()
  const store = useBaseStore('certificates')

  function open (id) {
    return $api.get(`/certificates/${id}/open`)
  }

  return {
    ...store,
    open
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCertificates, import.meta.hot))
}
