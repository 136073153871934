import validate from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import detect_45tech_45global from "/home/runner/work/certificates-app/certificates-app/middleware/detectTech.global.js";
import detect_45theme_45global from "/home/runner/work/certificates-app/certificates-app/middleware/detectTheme.global.js";
import is_45free_45weekend_45global from "/home/runner/work/certificates-app/certificates-app/middleware/isFreeWeekend.global.js";
import set_45theme_45global from "/home/runner/work/certificates-app/certificates-app/middleware/setTheme.global.js";
import trial_45exam_45global from "/home/runner/work/certificates-app/certificates-app/middleware/trialExam.global.js";
import url_45shortener_45global from "/home/runner/work/certificates-app/certificates-app/middleware/urlShortener.global.js";
import manifest_45route_45rule from "/home/runner/work/certificates-app/certificates-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  detect_45tech_45global,
  detect_45theme_45global,
  is_45free_45weekend_45global,
  set_45theme_45global,
  trial_45exam_45global,
  url_45shortener_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "detect-coupon": () => import("/home/runner/work/certificates-app/certificates-app/middleware/detectCoupon.js"),
  "marketing-tracking": () => import("/home/runner/work/certificates-app/certificates-app/middleware/marketingTracking.js"),
  "require-free-weekend": () => import("/home/runner/work/certificates-app/certificates-app/middleware/requireFreeWeekend.js"),
  "require-guest": () => import("/home/runner/work/certificates-app/certificates-app/middleware/requireGuest.js"),
  "require-session": () => import("/home/runner/work/certificates-app/certificates-app/middleware/requireSession.js"),
  "require-teams-order": () => import("/home/runner/work/certificates-app/certificates-app/middleware/requireTeamsOrder.js"),
  "require-upgradeable": () => import("/home/runner/work/certificates-app/certificates-app/middleware/requireUpgradeable.js"),
  "setup-voucher": () => import("/home/runner/work/certificates-app/certificates-app/middleware/setupVoucher.js"),
  "validate-session": () => import("/home/runner/work/certificates-app/certificates-app/middleware/validateSession.js")
}