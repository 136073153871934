import qs from 'qs'

export default function () {
  const config = useRuntimeConfig()
  const tech = useTech()

  const githubUrl = computed(() => {
    const query = qs.stringify({
      redirect_uri: `${config.public.APP_ROOT}/login/github/callback${tech.id ? '/' + tech.id : ''}`,
      scope: 'user:email',
      client_id: config.public.GITHUB_CLIENT_ID
    })

    return `https://github.com/login/oauth/authorize?${query}`
  })

  const discordUrl = computed(() => {
    const query = qs.stringify({
      tech: tech.id
    })

    return encodeURI(`${config.public.API_URL}/login/discord?${query}`)
  })

  const socials = computed(() => {
    return [
      {
        name: 'GitHub',
        route: githubUrl.value
      },
      {
        name: 'Discord',
        route: discordUrl.value
      }
    ]
  })

  return {
    socials
  }
}
